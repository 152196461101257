// src/pages/contact.js
import * as React from 'react';
import Contact from '../components/Contact0/Contact';
import Layout from '../components/layout';

const ContactPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <Contact apiUrl="https://admin.jets-kitakyushu.com/contact" />
      </Layout>
    </div>
  );
};

export default ContactPage;
